.progress {
  display: flex;
  margin-bottom: 34px;
}

.progress .progress_disabled {
  width: 11px;
  height: 11px;
  background-color: var(--color-gray-3);
  border-radius: 5px;
  font-size: 0;
  text-indent: -999px;
}

.progress .progress_active {
  width: 49px;
  background-color: var(--color-blue-4);
}

.progress > div + div {
  margin-left: 8px;
}

.progress .progress_active {
  width: 49px;
  background-color: var(--color-blue-4);
  animation: scale-up-hor-center 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) both;
}

@keyframes scale-up-hor-center {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
