@import 'assets/css/index.css';

.form_box {
  background-color: var(--color-gray-100);
  padding: 64px 87px;
  filter: drop-shadow(4px 6px 19px rgba(61, 89, 162, 0.3));
  border-radius: 9px;
  min-width: 502px;
}

@media (max-height: 640px) {
  .form_box {
    padding: 32px 74px;
    min-width: 492px;
  }
}

.title {
  font-size: 2em;
  line-height: 1em;
  text-align: center;
  font-weight: 700;
  color: var(--color-gray-800);
  margin: 0;
}

.title + *:not(p) {
  margin-top: 24px;
}

@media (max-height: 640px) {
  .title + *:not(p) {
    margin-top: 16px;
  }
}

.desc {
  color: var(--color-gray-600);
  font-weight: 700;
  text-align: center;
  margin-bottom: 32px;
}
