.tag {
  color: var(--color-gray-100);
  padding: 6px 16px;
  font-size: 10px;
  line-height: 12px;
  border-radius: 3px;
  display: inline-block;
  background-color: var(--color-gray-1);
  /* text-transform: lowercase; */
  font-weight: 600;
  margin-right: 5px;
  margin-bottom: 5px;
}

.tag_active {
  background-color: var(--color-success);
}

.tag_in_progress {
  background-color: var(--color-gray-400);
  color: var(--color-gray-600);
}

.tag_refused {
  background-color: var(--color-gray-900);
}

.tag_frozen {
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-gray-800);
  color: var(--color-gray-600);
}

.tag_closed {
  background-color: var(--color-gray-900);
  border: 1px solid var(--color-gray-900);
  color: var(--color-gray-100);
}

.tag_open {
  background-color: var(--color-blue-100);
  color: var(--color-blue-200);
}

.wide {
  width: 100%;
  margin: 0;
}
