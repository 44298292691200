@import 'assets/css/index.css';

.title {
  font-family: 'Roboto', sans-serif;
  font-size: 0.8em;
  font-weight: 400;
  padding: 5px 10px;
  border: 1px solid;
  border-radius: 3px;
}

.title.danger {
  border-color: var(--color-red-2);
  color: var(--color-red-2);
}

.title.in_progress {
  border-color: var(--color-blue-1);
  color: var(--color-blue-1);
}

.title.success {
  border-color: var(--color-green-1);
  color: var(--color-green-1);
}
