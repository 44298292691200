.wrapper {
  margin-top: 65px;
  max-width: 445px;
  width: 100%;
}

.form {
  margin-bottom: 32px;
}

.disclaimer {
  color: var(--color-gray-8);
  font-size: 1em;
  line-height: 1.2em;
  margin-top: 0;
}

.form textarea {
  height: 150px;
}

.sent {
  color: var(--color-gray-8);
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 24px;
}

.sent svg {
  margin-right: 18px;
}

/* Checkbox Group */
.checkbox {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}

.label_main {
  color: var(--color-blue-2);
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  display: block;
  margin-bottom: 8px;
}

.label_main.error {
  color: var(--color-red-1);
}

.description {
  font-size: 0.9em;
  line-height: 1.1em;
  color: var(--color-gray-8);
  display: inline-block;
  margin-bottom: 20px;
}

.label {
  font-size: 0.8em;
  color: var(--color-blue-2);
  height: 30px;
  border-radius: 30px;
  border: 1px solid var(--color-gray-8);
  padding: 5px 12px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 428px;
  overflow: hidden;
}

.label:before {
  content: '';
  position: absolute;
  border-radius: 3px;
  left: 0;
  top: 0;
}

.label.active {
  background-repeat: no-repeat;
  background-color: var(--color-blue-2);
  background-size: 18px 12px;
  background-position: 10px center;
  color: var(--color-gray-3);
}

.labelWithCheckIcon.active {
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2017%2013%22%3E%3Cpath%20fill%3D%22%23A6A6A5%22%20d%3D%22M15.57%202.845L14.238%201.51c-.13-.13-.353-.13-.48%200L6.66%208.623l-3.093-3.11c-.13-.13-.352-.13-.482%200L1.75%206.845c-.128.13-.128.352%200%20.48l4.67%204.67c.073.055.147.092.24.092s.167-.037.24-.093l8.67-8.67c.13-.13.13-.35%200-.48z%22%2F%3E%3C%2Fsvg%3E');
  padding-left: 40px;
}

.fieldset {
  border: none;
  padding: 0;
  margin-bottom: 20px;
}

.error_message {
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8em;
  margin-top: 6px;
}

.error_message {
  color: var(--color-red-1);
}
