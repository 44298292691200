@import 'assets/css/index.css';

.project_modal_header {
  margin-bottom: 32px;
}

.project_modal_header > span {
  margin-bottom: 16px;
}

.project_modal_title {
  font-size: 1.5em;
  line-height: 1.75em;
  color: var(--color-gray-800);
  margin: 0;
}

.project_modal_disclaimer {
  font-size: 1em;
  line-height: 1.75em;
  color: var(--color-gray-800);
  margin: 0;
}
.project_section_link {
  font-size: 0.875em;
  line-height: 0.875em;
  color: var(--color-blue-200);
  font-weight: 500;
  text-decoration: none;
}

.project_modal_subtitle {
  flex: 1 1 100%;
  margin: 0 0 16px 0;
  font-weight: 500;
  font-size: 1.125em;
  line-height: 1.3125em;
}

.project_modal_section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.project_modal_section + .project_modal_section {
  border-top: 1px solid var(--color-gray-400);
  padding-top: 24px;
  margin-top: 24px;
}

.project_modal_item {
  margin-bottom: 24px;
  flex: 1 0 33.333333%;
}

.project_modal_label {
  font-weight: 700;
  font-size: 0.875em;
  line-height: 1em;
  color: var(--color-gray-500);
  margin-bottom: 8px;
  display: block;
}

.project_modal_text {
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.3125em;
  color: var(--color-gray-800);
  margin: 0;
}

.project_modal_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
}

.project_modal_buttons > button {
  flex: 0 1 240px;
}

.project_modal_buttons button:first-child {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.project_modal_buttons button:first-child svg {
  transform: rotate(180deg);
  transform-origin: center;
  margin: 0 10px 4px 0;
  display: flex;
}
