@import 'assets/css/index.css';

.card {
  margin-bottom: 24px;
}

.content {
  padding: 16px 16px 1px 16px;
}

.line {
  margin-bottom: 30px;
}

.two_columns {
  max-width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.topic,
.text {
  font-family: 'Roboto', sans-serif;
}

.topic {
  color: var(--color-blue-2);
  display: block;
  font-size: 1em;
}

.text {
  color: var(--color-gray-1);
  font-size: 0.8em;
  margin-top: 10px;
  margin-bottom: 0;
}
