@import 'assets/css/index.css';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
}

.title,
.description {
  font-family: 'Roboto', sans-serif;
  color: var(--color-blue-2);
}

.title {
  font-size: 1em;
  margin-right: 10px;
}

.description {
  font-size: 0.8em;
  margin: 0 16px;
}

.wrapper {
  border-bottom: 1px solid var(--color-gray-3);
}

.wrapper:last-child {
  border-bottom: none;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.footer_text {
  color: var(--color-gray-8);
  font-family: 'Roboto', sans-serif;
  font-size: 0.8em;
}
