@import 'assets/css/index.css';

.fieldset {
  border: none;
  padding: 0;
  margin-bottom: 24px;
  margin-left: 0;
  margin-right: 0;
}

.label {
  color: var(--color-gray-800);
  font-size: 0.9em;
  line-height: 1em;
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.label.error {
  color: var(--color-error);
}

.error_message {
  color: var(--color-error);
  display: block;
  font-size: 0.8em;
  margin-top: 6px;
}
