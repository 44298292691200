@import 'assets/css/index.css';

.feedback {
  border-radius: 5px;
  display: flex;
  padding: 0 10px 0 25px;
  width: 100%;
  margin-bottom: 12px;
  color: var(--color-white);
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.feedback.is_open {
  transform: translateY(80px);
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.feedback.is_closed {
  transform: translateY(-80px);
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.feedback.error {
  background-color: var(--color-error);
}

.feedback.success {
  background-color: var(--color-success);
}

.icon {
  width: 1.5em;
  fill: var(--color-white);
  margin-right: 10px;
}

.text {
  font-size: 0.87em;
  line-height: 1em;
  margin: 20px 0;
  font-weight: 700;
  flex-grow: 1;
}

.close {
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  padding: 10px 15px;
}
