.header_out {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 20px 32px;
  z-index: 1;
}

.header_logo {
  height: 40px;
}

.header_text {
  color: var(--color-blue-200);
  font-size: 1.1em;
  font-weight: 500;
}
