@import 'assets/css/index.css';

.card {
  border: 1px solid var(--color-gray-3);
  border-radius: 4px;
  padding-top: 16px;
}

.header {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.header_line {
  border-bottom: 1px solid var(--color-gray-3);
}

.title {
  color: var(--color-blue-1);
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  font-weight: 500;
  margin: 0;
}
