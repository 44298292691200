@import 'assets/css/index.css';

.table {
  border-collapse: collapse;
  width: 100%;
}

.head_column,
.body_summary {
  font-family: 'Roboto', sans-serif;
  font-size: 0.8em;
}

.head_line {
  border-bottom: 1px solid var(--color-gray-3);
}

.head_column {
  color: var(--color-gray-1);
  text-align: left;
  font-weight: normal;
  padding-bottom: 6px;
  padding-top: 6px;
}

.head_column:first-child {
  padding-left: 16px;
}

.body_summary,
.body_badge {
  border-bottom: 1px solid var(--color-gray-3);
  padding-bottom: 10px;
  padding-top: 10px;
}

.body_summary {
  max-width: 27ch;
  padding-left: 16px;
  color: var(--color-blue-2);
}

.body_badge {
  text-align: center;
}
