@import 'assets/css/index.css';

:root {
  --select-border: #777;
  --select-focus: var(--color-blue-200);
  /* --select-focus: #2684FF; */
}

.select {
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--color-gray-800);
  cursor: pointer;
  display: grid;
  font-size: 0.9em;
  grid-template-areas: 'select';
  line-height: 1em;
  position: relative;
  width: 100%;
  min-height: 60px;
}

.select::after {
  content: '';
  width: 12px;
  height: 8px;
  clip-path: path(
    'M1.41 0.589844L6 5.16984L10.59 0.589844L12 1.99984L6 7.99984L0 1.99984L1.41 0.589844Z'
  );
  justify-self: end;
  z-index: 0;
  margin-right: 22px;
  background-color: var(--color-blue-200);
}

.standard_select,
.select:after {
  grid-area: select;
}

.standard_select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--color-blue-200);
  border-radius: inherit;
}

.standard_select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 20px 16px;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  z-index: 1;
}

.standard_select::-ms-expand {
  display: none;
}

.select.error {
  border-color: var(--color-error);
}
