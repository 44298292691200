@import 'assets/css/index.css';

.button {
  background-color: transparent;
  border: 1px solid;
  border-radius: 100px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 0.88em;
  padding: 16px 26px;
  transition: 0.1s;
}

.small {
  height: 34px;
  padding: 9px 10px;
  line-height: 0.8em;
}

.flat.primary {
  background-color: var(--color-blue-200);
  border-color: var(--color-blue-200);
  color: var(--color-gray-100);
}

.outline.primary {
  background-color: var(--color-gray-100);
  border: 2px solid var(--color-blue-200);
  color: var(--color-blue-200);
}

.outline.primary:hover {
  background-color: var(--color-blue-200);
  color: var(--color-white);
}

/* -- */
.flat.secondary {
  background-color: var(--color-blue-4);
  border-color: var(--color-blue-4);
  color: var(--color-white);
}

.outline.secondary {
  border-color: var(--color-blue-4);
  color: var(--color-blue-4);
}

.outline.secondary:hover {
  background-color: var(--color-blue-4);
  color: var(--color-white);
}

.disabled {
  background-color: var(--color-gray-300);
  border-color: transparent;
  color: var(--color-gray-500);
  cursor: not-allowed;
}

.expand-x {
  width: 100%;
}

.button svg {
  width: 12px;
  height: 12px;
  margin-left: 10px;
}

.white {
  background-color: var(--color-gray-100);
  color: var(--color-gray-800);
  font-weight: 500;
  border-color: var(--color-gray-100);
}

.white:hover {
  background-color: var(--color-gray-800);
  color: var(--color-gray-100);
  border-color: var(--color-gray-800);
}
