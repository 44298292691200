@import 'assets/css/index.css';

.no_project {
  width: 100%;
  border: 1px dashed var(--color-gray-500);
  border-radius: 9px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no_project_figure,
.no_project_image {
  margin: 0;
  display: block;
}

.no_project_title {
  font-size: 1.125em;
  line-height: 1.4375em;
  margin: 0 0 10px 0;
  font-weight: 700;
  color: var(--color-blue-200);
}

.no_project_desc {
  font-size: 0.8em;
  line-height: 1.125em;
  margin: 0 0 16px 0;
  font-weight: 400;
  color: var(--color-gray-700);
}

.no_project_button {
  width: 178px;
}
