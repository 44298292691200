@import 'assets/css/index.css';

.page {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  gap: 0 16px;
  grid-template-areas: '. .';
  height: 100vh;
}

@media (max-width: 1024px) {
  .page {
    grid-template-columns: 30% 70%;
  }
}

@media (max-width: 1024px) {
  .page {
    grid-template-columns: 35% 65%;
  }
}

.page .illustration {
  background-color: var(--color-gray-200);
  align-items: center;
  justify-content: center;
  display: flex;
}

@media (max-width: 1024px) {
  .page .illustration svg {
    /* transform: translateX(-10%); */
  }
}

.page .illustration .ref {
  position: absolute;
  bottom: 16px;
  left: 16px;
  font-size: 0.625em;
  color: var(--color-blue-200);
  text-decoration: none;
}

.page .main {
  align-items: center;
  justify-content: center;
  display: flex;
}

.page .main [class*='form_box'] [class*='title'] {
  font-size: 1.5em;
  line-height: 1em;
}

.page .main [class*='_form_'] fieldset:first-child {
  margin-bottom: 16px;
}

.logo {
  margin-bottom: 47px;
}

.title {
  color: var(--color-blue-2);
  font-size: 1.5em;
  font-weight: 500;
  margin-bottom: 38px;
}

.text {
  color: var(--color-blue-2);
  font-size: 0.87em;
  margin: 0;
  text-align: right;
  line-height: 0;
}

.text + .text {
  margin-top: 0;
}

.link {
  color: var(--color-blue-200);
  font-weight: 500;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.link svg {
  margin-left: 5px;
}

.signin {
  margin-bottom: 16px;
}

.alert {
  margin-bottom: 10px;
  max-width: 564px;
}

.login_error {
  font-size: 0.75em;
  color: var(--color-error);
  transform: translateY(-20px);
  margin: 0;
  line-height: 0;
}

.input + p {
  margin: 0;
}

.form fieldset + p + fieldset {
  margin-bottom: 32px;
}

@media (max-height: 640px) {
  .page .illustration {
    zoom: 80%;
  }

  .page .main [class*='_form_'] {
    zoom: 95%;
  }

  .form fieldset + p + fieldset {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .page .main [class*='_form_box'] {
    padding: 34px 62px;
  }

  .links {
    padding-top: 20px;
  }
}
