@import 'assets/css/index.css';

.fieldset {
  border: none;
  margin-bottom: 32px;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  position: relative;
}

.input {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}

.input:checked ~ .label:before {
  background: var(--color-blue-200)
    url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2017%2013%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M15.57%202.845L14.238%201.51c-.13-.13-.353-.13-.48%200L6.66%208.623l-3.093-3.11c-.13-.13-.352-.13-.482%200L1.75%206.845c-.128.13-.128.352%200%20.48l4.67%204.67c.073.055.147.092.24.092s.167-.037.24-.093l8.67-8.67c.13-.13.13-.35%200-.48z%22%2F%3E%3C%2Fsvg%3E')
    center no-repeat;
  border-color: var(--color-blue-200);
}

.label {
  color: var(--color-gray-800);
  cursor: pointer;
  display: block;
  font-size: 0.875em;
  line-height: 1.5em;
  padding-left: 26px;
  position: relative;
  z-index: 1;
  font-weight: 400;
}

.label:before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  border: 2px solid var(--color-gray-1);
  border-radius: 3px;
  left: 0;
  top: 0;
}

.label a {
  color: var(--color-blue-1);
}

.label a:hover {
  text-decoration: none;
}

.error_message {
  color: var(--color-red-1);
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8em;
  margin-top: 6px;
}
