.card {
  margin-bottom: 15px;
}

.tasks,
.pendencies {
  height: 100%;
  max-height: 300px;
  overflow-y: scroll;
}

.roadmap {
  max-width: 100%;
}

.wrapper {
  display: grid;
  grid-template-columns: 365px 1fr;
  column-gap: 16px;
}
