@import 'assets/css/index.css';

.page {
  align-items: center;
  background-color: var(--color-gray-200);
  display: flex;
  height: 100vh;
  justify-content: center;
}

.main {
  max-width: 676px;
  width: 676px;
}

.main div p {
  max-width: 400px;
  margin: 24px auto 32px;
}

.form {
  max-width: 328px;
  margin: 0 auto;
}

.alert {
  margin-bottom: 10px;
}

.links {
  text-align: center;
  padding-top: 40px;
  font-size: 0.8em;
}

.text {
  margin-top: 0;
  margin-bottom: 24px;
}

.link {
  color: var(--color-blue-200);
  font-weight: 500;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.link svg {
  margin-left: 4px;
}

@media (max-height: 620px) {
  .page .main [class*='_form_'] {
    zoom: 98%;
  }

  .links {
    padding-top: 16px;
  }

  .page .text {
    margin: 8px auto 16px;
  }
}
