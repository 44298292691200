@import 'assets/css/index.css';

.page {
  background-color: var(--color-gray-200);
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  grid-template-areas: '. .';
  height: 100vh;
  padding-right: 16px;
}

@media (max-width: 1335px) {
  .page {
    grid-template-columns: 25% 75%;
  }
}

.page .illustration {
  background-color: var(--color-gray-200);
  align-items: center;
  justify-content: flex-end;
  display: flex;
  position: relative;
  padding-top: 170px;
}

.page .illustration svg {
  position: relative;
  z-index: 1;
  transform: translateX(145px);
  pointer-events: none;
}

@media (max-width: 1335px) {
  .page .illustration svg {
    transform: translateX(25px) scale(1.5);
  }
}

.page .illustration .ref {
  position: absolute;
  bottom: 16px;
  left: 16px;
  font-size: 0.625em;
  color: var(--color-blue-200);
  text-decoration: none;
}

.page .main {
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

.container {
  max-width: 850px;
}

.form_half {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

.links {
  text-align: center;
  padding-top: 40px;
  font-size: 0.8em;
}

.text {
  margin-top: 0;
}

.link {
  color: var(--color-blue-200);
  font-weight: 500;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.link svg {
  margin-left: 4px;
}

@media (max-height: 845px) {
  .page .illustration {
    zoom: 72%;
  }

  .page .main [class*='_form_'] {
    zoom: 90%;
  }

  .page .main [class*='_form_box'] {
    min-height: 402px;
    padding: 34px 57px;
  }

  .links {
    padding-top: 20px;
  }
}

@media (max-width: 1024px) {
  .page .main [class*='_form_box'] {
    padding: 34px 42px;
  }

  .links {
    padding-top: 20px;
  }
}
