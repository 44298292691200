@import 'assets/css/index.css';

.page {
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: '. .';
  height: 100vh;
}

.page .illustration {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  position: relative;
}

.page .illustration svg {
  position: relative;
  z-index: 1;
}

.page .illustration .ref {
  position: absolute;
  bottom: 16px;
  left: 16px;
  font-size: 0.625em;
  color: var(--color-blue-200);
  text-decoration: none;
}

.page .content {
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

.page .box {
  max-width: 310px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.title {
  color: var(--color-blue-200);
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 24px;
  margin-top: 0;
}

.text {
  color: var(--color-gray-600);
  font-size: 1em;
  font-weight: 400;
  margin: 0 0 16px 0;
}

.icon {
  max-width: 66px;
  margin-bottom: 30px;
}
