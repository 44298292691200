@import 'assets/css/index.css';

.my_project_header {
  background-color: var(--color-gray-200);
  padding: 48px 0 24px;
}

.container {
  max-width: var(--container);
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

.my_project_header_title {
  color: var(--color-gray-800);
  font-weight: 700;
  font-size: 1.25em;
  margin: 0 0 10px;
}

.my_project_header_desc {
  color: var(--color-gray-500);
  font-weight: 400;
  font-size: 0.87em;
}
