@import 'assets/css/index.css';

.header {
  border-bottom: 1px solid var(--color-gray-3);
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  padding-top: 15px;
}

.content {
  display: flex;
  justify-content: space-between;
  max-width: var(--container);
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.logo {
  max-width: 151px;
}

.username,
.exit_button {
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  margin: 0;
  text-align: right;
}

.username {
  font-weight: 500;
}

.exit_button {
  cursor: pointer;
}
