@import 'assets/css/index.css';

.wrapper {
  max-width: 570px;
}

.title,
.description {
  font-family: 'Roboto', sans-serif;
  color: var(--color-blue-2);
  line-height: 1.5em;
}

.title {
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 0;
  margin-top: 0;
}

.description {
  font-size: 16px;
}

.two_columns_input_first_big_one,
.two_columns_input_second_big_one {
  display: grid;
  column-gap: 20px;
}

.two_columns_input_first_big_one {
  grid-template-columns: auto 200px;
}

.two_columns_input_second_big_one {
  grid-template-columns: 200px auto;
}

.form {
  margin-top: 25px;
  display: block;
  width: 100%;
  max-width: 100%;
}
