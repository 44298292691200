@import 'assets/css/index.css';

.grid {
  display: grid;
  column-gap: 20px;
}

.grid_column_two {
  grid-template-columns: 1fr 1fr;
}

.grid_column_three {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid_column_four {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
