@import 'assets/css/index.css';

.hero {
  background-color: var(--color-blue-200);
  height: 184px;
}

.hero_container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.hero_figure {
  padding: 0;
  margin: 0;
  align-self: flex-end;
}

.hero_figure svg {
  display: block;
}

.hero_text {
  max-width: 415px;
}

.hero_title {
  color: var(--color-gray-100);
  font-size: 2em;
  font-weight: 700;
  margin: 0 0 10px;
}

.hero_desc {
  color: var(--color-gray-100);
  font-size: 0.8em;
  line-height: 1.25em;
  font-weight: 500;
  margin: 0 100px 0 0;
}
