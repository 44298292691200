@import 'assets/css/index.css';

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, 255px);
  gap: 30px;
}

.link {
  align-items: center;
  cursor: pointer;
  display: flex !important;
  flex-direction: column;
  display: block;
  padding: 0;
  text-decoration: none;
  width: 100%;
}

.text {
  color: var(--color-blue-200);
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  line-height: 1.9em;
  font-weight: 400;
}
