@import '../../assets/css/index.css';

.list {
  width: 100%;
}

.list_item {
  border: 1px solid var(--color-gray-3);
  border-radius: 3px;
  padding: 20px 20px 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list_item + .list_item {
  margin-top: 12px;
}

.list_title {
  font-size: 1em;
  line-height: 1.4em;
  font-weight: 500;
  margin: 0;
  color: var(--color-gray-1);
}

.list_link {
  color: var(--color-gray-1);
  text-decoration: none;
}

.list_link:hover {
  color: var(--color-gray-8);
}
