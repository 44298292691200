@import 'assets/css/index.css';

.task {
  border-bottom: 1px solid var(--color-gray-3);
}

.task:last-child {
  border-bottom: none;
}

.wrapper {
  padding: 9px 16px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.date,
.description,
.title {
  font-family: 'Roboto', sans-serif;
  margin: 0;
}

.title {
  color: var(--color-blue-2);
  font-size: 1em;
  max-width: 45ch;
}

.description {
  color: var(--color-gray-1);
  font-size: 0.8em;
  margin-top: 10px;
  margin-bottom: 12px;
  line-height: 20px;
}

.date {
  color: var(--color-gray-8);
  font-size: 0.8em;
}
