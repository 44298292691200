@import 'assets/css/index.css';

.menu {
  padding: 20px 20px 20px 0;
  border-right: 1px solid var(--color-gray-3);
  min-height: 100%;
}

.item {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 30px;
}

.item svg {
  flex-shrink: 0;
}

.title {
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  margin-left: 11px;
}

.title.active {
  color: var(--color-blue-2);
}

.title.unactive {
  color: var(--color-gray-8);
}
