@import 'assets/css/index.css';

.selective_process {
  border: 2px solid var(--color-gray-800);
  padding: 32px;
  border-radius: 9px;
  min-height: 471px;
}

.selective_process_title {
  font-size: 1.375em;
  line-height: 1.5625em;
  font-weight: 700;
  color: var(--color-gray-800);
  margin: 0 0 24px;
}

.selective_process_list {
  /* border: 1px solid #ccc; */
}

.selective_process_item {
  padding-top: 6px;
  padding-bottom: 22px;
  padding-left: 32px;
  position: relative;
}

.selective_process_item:before {
  content: '';
  position: absolute;
  left: 11px;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: var(
    --color-blue-100
  ); /* For browsers that do not support gradients */
  background-image: repeating-linear-gradient(
    var(--color-blue-100),
    var(--color-blue-100) 8px,
    white 8px,
    white 10px
  );
}

.selective_process_item:last-child:before {
  display: none;
}

.selective_process_item:last-child .selective_process_item_bar {
  display: none;
}

.selective_process_item_title {
  font-size: 0.87em;
  line-height: 1em;
  font-weight: 500;
  color: var(--color-blue-100);
  margin: 0 0 8px;
}

.selective_process_item_desc {
  font-size: 0.87em;
  line-height: 1.3em;
  font-weight: 400;
  color: var(--color-gray-800);
  margin: 0;
  padding-right: 50px;
  display: none;
}

.selective_process_item_desc span {
  color: var(--color-blue-200);
  font-weight: 700;
}

/* icons */
.selective_process_item_icons {
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-blue-100);
  background-color: var(--color-gray-100);
}

.selective_process_item_bar {
  position: absolute;
  left: 11px;
  top: 0;
  width: 2px;
  height: 100%;
}

.icon_check {
  width: 12px;
  height: 9px;
  overflow: visible;
  stroke: white;
  stroke-width: 0.5;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.icon_negative {
  width: 16px;
  height: 16px;
  overflow: visible;
  stroke: white;
}

/* buttons */
.selective_process_item_buttons {
  margin-top: 16px;
}

.selective_process_item_buttons button {
  width: 145px;
}

.selective_process_item_buttons button + button {
  margin-left: 18px;
}

/* complete */
.status_complete .selective_process_item_title {
  color: var(--color-success);
}

.status_complete .selective_process_item_bar {
  background-color: var(--color-success);
}

.status_complete .selective_process_item_icons {
  border-color: var(--color-success);
  background-color: var(--color-success);
}

.status_complete .selective_process_item_desc,
.status_waiting .selective_process_item_desc,
.status_refused .selective_process_item_desc {
  display: block;
}

/* waiting */
.status_waiting .selective_process_item_title {
  color: var(--color-blue-200);
}

.status_waiting .selective_process_item_icons {
  border-color: var(--color-blue-200);
}

/* refused */
.status_refused .selective_process_item_title {
  color: var(--color-error);
}
.status_refused .selective_process_item_icons {
  border-color: var(--color-error);
  background-color: var(--color-error);
}
