@import '../../../../assets/css/index.css';

.detail {
  max-width: 668px;
}

.detail_section {
  margin-bottom: 25px;
  display: flex;
  column-gap: 64px;
}

.detail_item {
  flex: 1;
}

.detail_label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  color: var(--color-blue-2);
  margin-bottom: 5px;
  display: block;
}

.detail_text {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  color: var(--color-gray-1);
  margin: 0 0 10px;
}

.detail_buttons button + button {
  margin-left: 16px;
}

.detail_link {
  font-size: 12px;
  line-height: 24px;
  color: var(--color-blue-1);
}

.detail_link:hover {
  text-decoration: none;
}
