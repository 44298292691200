.success_sent {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.success_sent_title {
  font-size: 2em;
  line-height: 1em;
  font-weight: 600;
  margin-bottom: 40px;
  color: var(--color-blue-200);
}

.success_sent_desc {
  font-size: 1em;
  line-height: 1.5em;
  font-weight: 400;
  margin-bottom: 40px;
  color: var(--color-gray-600);
}

.success_sent_btn {
  max-width: 240px;
  width: 100%;
}
