.dashboard {
  display: grid;
  grid-template-rows: 70px auto;
  min-height: 100vh;
}

.wrapper {
  display: flex;
  justify-content: center;
}

.content {
  display: grid;
  grid-template-columns: 200px auto;
  max-width: var(--container);
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.main {
  padding: 30px 0 30px 30px;
}

.title,
.description {
  font-family: 'Roboto', sans-serif;
  color: var(--color-blue-2);
  line-height: 1.5em;
}

.title {
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 0;
  margin-top: 0;
}

.description {
  font-size: 16px;
  max-width: 825px;
}

.back {
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 20px;
  text-decoration: underline;
  color: var(--color-blue-1);
  display: inline-block;
}

.back:hover,
.back:focus {
  text-decoration: none;
}
