@import 'assets/css/index.css';

.page {
  align-items: center;
  background-color: var(--color-gray-200);
  display: flex;
  height: 100vh;
  justify-content: center;
}

.main {
  max-width: 676px;
  width: 676px;
}

.form {
  max-width: 328px;
  margin: 0 auto;
}

.alert {
  margin-bottom: 10px;
}

.disclaimer {
  max-width: 328px;
  margin: 24px auto;
}
