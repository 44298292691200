.wrapper {
  margin-top: 64px;
  max-width: 825px;
  width: 100%;
  overflow: hidden;
}

.title {
  font-size: 32px;
  line-height: 24px;
  font-weight: 500;
  color: var(--color-blue-2);
  margin: 0 0 42px;
  font-family: 'Roboto', sans-serif;
}

.selective_process_item {
  padding-top: 12px;
  padding-bottom: 40px;
  padding-left: 90px;
  position: relative;
}

.selective_process_item:before {
  content: '';
  position: absolute;
  left: 24px;
  top: 0;
  width: 3px;
  height: 100%;
  background-color: var(--color-gray-2);
}

.selective_process_item:last-child:before {
  display: none;
}

.bar {
  position: absolute;
  left: 24px;
  top: 0;
  width: 3px;
  height: 100%;
  background: var(--color-gray-2);
}

.selective_process_title {
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  color: var(--color-blue-2);
  font-family: 'Roboto', sans-serif;
  margin: 0 0 8px;
}

.selective_process_desc {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--color-gray-1);
  font-family: 'Roboto', sans-serif;
  margin: 0;
}

.selective_process_buttons {
  margin-top: 20px;
}

.selective_process_buttons button + button {
  margin-left: 8px;
}

.selective_process_desc span {
  color: var(--color-red-1);
  font-weight: 600;
}

.icons {
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(
    circle at center,
    var(--color-blue-2) 0,
    var(--color-blue-2) 4px,
    var(--color-gray-2) 4px,
    var(--color-gray-2) 100%
  );
}

.icon_check {
  width: 28px;
  height: 22px;
  overflow: visible;
  stroke: white;
  stroke-width: 0.5;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.icon_rotate {
  width: 24px;
  height: 24px;
}

.status_complete .bar,
.status_waiting .bar {
  background: var(--color-blue-2);
}

.status_complete:last-child .bar,
.status_waiting:last-child .bar {
  display: none;
}

.status_complete .icons,
.status_waiting .icons {
  background: var(--color-gray-2);
}

.selective_process_item.type_grouped_bottom .icons {
  height: 100%;
  border-radius: 25px 25px 0 0;
  background-position: center -15px;
  align-items: flex-start;
  padding-top: 10px;
}

.selective_process_item.type_grouped_top {
  padding-bottom: 12px;
}

.selective_process_item.type_grouped_top .icons {
  border-radius: 0 0 25px 25px;
  align-items: flex-end;
  padding-bottom: 10px;
}

.selective_process_item.type_grouped_top .bar,
.selective_process_item.type_grouped_top:before {
  display: none;
}

.negative_info {
  font-size: 16px;
  line-height: 24px;
  color: var(--color-gray-1);
}

.negative_info a {
  color: var(--color-blue-1);
}

.negative_info a:hover {
  text-decoration: none;
}

.modal {
  text-align: center;
  transform: translateY(-15px);
}

.modal_title {
  font-size: 32px;
  line-height: 24px;
  margin-bottom: 20px;
  color: var(--color-blue-2);
}

.modal_desc {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 42px;
  color: var(--color-blue-2);
  max-width: 426px;
}
