html,
body {
  font-size: 16px;
  height: 100%;
}

body {
  overflow: overlay;
  font-family: 'Roboto', sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box !important;
}

/* @media (max-height: 640px) {
  body > * {
    zoom: 80%;
  }
} */
