.bar {
  height: 48px;
  background-color: var(--color-blue-2);
  position: fixed;
  width: 100vw;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50px);
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    opacity 0.3s ease, visibility 0.3s ease;
}

.bar .hold {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  color: var(--color-white);
  height: 100%;
  padding: 0 48px;
  position: relative;
}

.bar .text {
  font-size: 1em;
  line-height: 1.2em;
  margin: 0;
  padding: 0;
}

.bar .link {
  color: var(--color-blue-1);
  padding-left: 5px;
}

.bar.open {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: transform 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    opacity 0.4s ease, visibility 0.4s ease;
}

.bar.open ~ [class*='dashboard'] {
  padding-top: 48px;
}

.bar.is_close {
  height: 48px;
  background-color: var(--color-blue-2);
  position: fixed;
  width: 100vw;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50px);
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    opacity 0.3s ease, visibility 0.3s ease;
}

.bar.is_close ~ [class*='dashboard'] {
  padding-top: 0;
}

.close {
  border: none;
  width: 14px;
  height: 14px;
  color: white;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 20px;
  top: calc(50% - 7px);
  background: url('assets/img/close.svg') no-repeat center;
  font-size: 0;
  line-height: 0;
}
