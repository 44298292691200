@import 'assets/css/index.css';

.title {
  font-family: 'Roboto', sans-serif;
  color: var(--color-blue-1);
  font-size: 1.5em;
  margin-bottom: 5px;
  margin-top: 0;
}

.box {
  margin-bottom: 30px;
  position: relative;
  height: 112px;
  min-height: 112px;
  overflow: hidden;
  cursor: pointer;
  transition: min-height 0.3s ease;
  padding-top: 80px;
}

.box:focus {
  outline: 1px dotted var(--color-blue-1);
}

.box_toggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 42px 25px 42px;
  outline: none;
}

.percent {
  color: var(--color-gray-1);
  font-size: 0.8em;
}

.box [class*='_progress_'] {
  position: absolute;
  left: 0;
  bottom: -1px;
}

.box form {
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.4s ease-in, transform 0.3s;
}

.is_open {
  height: auto;
  min-height: 500px;
  max-height: 10000px;
  transition: min-height 0.3s ease, max-height 0.3s ease;
}

.is_open form {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: opacity 0.4s ease-in, transform 0.3s;
}
