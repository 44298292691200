@import 'assets/css/index.css';

.dropdown_menu {
  position: absolute;
  border: 1px solid var(--color-gray-800);
  top: 100%;
  max-width: 226px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  background-color: var(--color-gray-100);
  box-shadow: 5px 5px 0 1px var(--color-gray-800);
  transition: transform 0.3s ease, opacity 0.2s ease, visibility 0.1s ease;
  opacity: 0.5;
  z-index: 0;
  visibility: hidden;
  transform: translateY(-10px);
}

.dropdown_menu_aditional {
  display: none;
}

@media (max-width: 940px) {
  .dropdown_menu_aditional {
    display: block;
  }
}

.dropdown_menu_link {
  font-size: 0.875em;
  line-height: 1em;
  font-weight: 500;
  color: var(--color-gray-800);
  padding: 16px;
  display: block;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.dropdown_menu_icon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  text-align: center;
}

.dropdown_menu_link + .dropdown_menu_link,
.dropdown_menu_aditional + .dropdown_menu_link {
  border-top: 1px solid var(--color-gray-800);
}

.dropdown_menu_link:hover {
  background-color: var(--color-blue-100);
  color: var(--color-blue-200);
}
