.wrapper {
  margin-top: 65px;
  max-width: 445px;
  width: 100%;
}

.form {
  margin-bottom: 32px;
}

.disclaimer {
  color: var(--color-gray-8);
  font-size: 1em;
  line-height: 1.2em;
  margin-top: 0;
}
