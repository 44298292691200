.project {
  border-radius: 4px;
  border: 1px solid var(--color-gray-8);
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
}

.label,
.text {
  font-size: 16px;
  line-height: 24px;
}

.label {
  color: var(--color-blue-2);
  font-weight: 500;
}

.text {
  color: var(--color-gray-1);
  margin: 0 0 12px;
  font-weight: 400;
}

.bottom {
  align-self: flex-end;
  justify-self: start;
}

.frozen {
  border: 1px solid rgba(166, 166, 165, 0.5);
  background: url('assets/img/snowflake.svg') no-repeat right bottom;
}

.frozen div:first-child {
  opacity: 0.5;
}

.interested {
  background: url('assets/img/checked_blue.svg') no-repeat right bottom;
}
