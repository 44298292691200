@import 'assets/css/index.css';

.wave {
  overflow: visible;
  stroke: var(--color-blue-4);
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.ellipse {
  background-color: var(--color-blue-4);
  position: absolute;
  width: 353px;
  height: 353px;
  border-radius: 50%;
  left: 61px;
  top: 67px;
  z-index: 1;
}

.ellipse_outilne {
  position: absolute;
  width: 198px;
  height: 198px;
  border-radius: 50%;
  border: 1px solid #000;
}

.ellipse_outilne_1,
.ellipse_outilne_5 {
  bottom: -130px;
  left: calc(50% - 140px);
}

.ellipse_outilne_2 {
  bottom: -74px;
  left: -71px;
}

.ellipse_outilne_3 {
  top: 58px;
  left: -126px;
}

.ellipse_outilne_4 {
  top: -124px;
  left: calc(50% - 170px);
}

.girl {
  position: absolute;
  right: 0;
  top: 65px;
  z-index: 2;
  opacity: 0;
  transform: translateX(-100px);
}

.signs {
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 34px;
}

.signs svg {
  display: block;
}

.calendar {
  z-index: 2;
  position: absolute;
  top: 119px;
  left: 129px;
}

.docs {
  z-index: 2;
  position: absolute;
  top: 115px;
  left: 119px;
}

.vitrine {
  z-index: 2;
  position: absolute;
  top: 115px;
  left: 53px;
}

.megafone {
  z-index: 2;
  position: absolute;
  top: 114px;
  left: 61px;
}
