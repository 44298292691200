:root {
  --color-white: #ffffff;
  --color-blue-1: #2196f3;
  --color-blue-2: #3e3e5c;
  --color-blue-3: #1c2638;
  --color-blue-4: #0049ff;
  --color-gray-1: #717171;
  --color-gray-2: #f4f4f4;
  --color-gray-3: #dcdcdc;
  --color-gray-4: #fdecea;
  --color-gray-5: #fff4e5;
  --color-gray-6: #e8f4fd;
  --color-gray-7: #edf7ed;
  --color-gray-8: #a6a6a5;
  --color-red-1: #f44336;
  --color-red-2: #e10050;
  --color-green-1: #53944a;
  --color-green-2: #43a047;

  --color-gray-100: #fcfcfc;
  --color-gray-200: #f1f3f9;
  --color-gray-300: #dee3ed;
  --color-gray-400: #c2c9d6;
  --color-gray-500: #8f96a3;
  --color-gray-600: #5e636e;
  --color-gray-800: #1d1e20;
  --color-gray-900: #111213;

  --color-blue-100: #d2dfff;
  --color-blue-200: #0049ff;

  --color-error: #e04576;
  --color-success: #00a15e;
}
