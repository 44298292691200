@import 'assets/css/index.css';

.project_box {
  background-color: var(--color-gray-100);
  border: 2px solid var(--color-gray-800);
  border-radius: 9px;
  padding: 24px 24px 74px;
  max-width: 241px;
  width: 100%;
  min-height: 227px;
  position: relative;
}

@media (max-width: 840px) {
  .project_box {
    max-width: 100%;
  }
}

.project_box span + h2 {
  margin-top: 16px;
}

.project_box > [class*='_tag_'] {
  min-width: 90px;
  text-align: center;
}

.has_image {
  padding-top: 144px;
}

.project_box_figure {
  position: absolute;
  display: block;
  margin: 0;
  left: 0;
  top: 0;
  height: 128px;
  width: 100%;
  overflow: hidden;
  border-radius: 9px 9px 0 0;
}

.project_box_image {
  object-fit: contain;
  width: 100%;
}

.project_box_title {
  font-size: 1em;
  line-height: 1.375em;
  font-weight: 700;
  margin: 0 0 16px 0;
}

.project_box_desc {
  font-size: 0.75em;
  line-height: 1.1em;
  font-weight: 400;
  margin: 0 0 16px;
}

.project_box_btn {
  position: absolute;
  bottom: 24px;
  left: 24px;
  right: 24px;
  width: calc(100% - 48px);
}

@media (min-width: 840px) and (max-width: 965px) {
  .project_box .project_box_btn {
    height: 42px;
  }
}

.project_box_label {
  display: block;
  font-size: 0.75em;
  line-height: 0.8em;
  font-weight: 700;
  margin: 0 0 10px 0;
}
