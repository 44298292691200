@import '../../../../assets/css/index.css';

.business_informations {
  display: grid;
  grid-template-columns: 1fr 170px;
  column-gap: 20px;
}

.requirements {
  margin-bottom: 30px;
}

.requirements,
.project_informations {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
}

.line {
  border: none;
  border-bottom: 1px solid var(--color-gray-3);
  margin-top: 36px;
  margin-bottom: 36px;
}

.title_description {
  font-family: 'Roboto', sans-serif;
  color: var(--color-blue-2);
  font-size: 1em;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
}

.description {
  font-family: 'Roboto', sans-serif;
  color: var(--color-gray-1);
  font-size: 0.8em;
  font-style: normal;
  font-weight: normal;
  margin-top: 5px;
  margin-bottom: 0;
  line-height: 20px;
  white-space: pre-wrap;
}

.title {
  font-family: 'Roboto', sans-serif;
  color: var(--color-blue-2);
  display: block;
  font-size: 1.3em;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 24px;
}

.disclaimer {
  font-size: 12px;
  line-height: 20px;
  color: var(--color-gray-1);
}
