@import 'assets/css/index.css';

.fieldset {
  border: none;
  padding: 0;
  margin-bottom: 24px;
  margin-left: 0;
  margin-right: 0;
}

.label {
  color: var(--color-gray-800);
  font-size: 0.9em;
  line-height: 1em;
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.label.error {
  color: var(--color-error);
}

.input {
  border: 1px solid var(--color-gray-800);
  border-radius: 5px;
  font-size: 0.9em;
  line-height: 1em;
  padding: 20px 16px;
  width: 100%;
  color: var(--color-gray-800);
  font-weight: 400;
  min-height: 60px;
}

.input:focus,
.input:focus-visible {
  border: 2px solid var(--color-blue-200);
  outline-color: var(--color-blue-200);
  padding-top: 19px;
  padding-bottom: 19px;
}

.input::placeholder {
  color: var(--color-gray-500);
}

.input.error {
  border: 2px solid var(--color-error);
}

.error_message,
.tooltip {
  display: block;
  font-size: 0.8em;
  margin-top: 6px;
}

.error_message {
  color: var(--color-error);
}

.tooltip {
  color: var(--color-gray-8);
}

.input[type='password'] {
  padding-right: 40px;
}

.with_icon {
  position: relative;
  width: 100%;
}

.with_icon .button {
  background: transparent;
  border: none;
  height: 100%;
  width: 45px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

input[disabled] {
  border: 1px solid var(--color-gray-500);
  background-color: #efefef;
}
