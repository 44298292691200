@import 'assets/css/index.css';

/* Works on Firefox */
.modal * {
  scrollbar-width: thin;
  scrollbar-color: var(--color-gray-800) white;
}

/* Works on Chrome, Edge, and Safari */
.modal *::-webkit-scrollbar {
  width: 8px;
}

.modal *::-webkit-scrollbar-track {
  background: white;
  border: 1px solid var(--color-gray-800);
  border-radius: 20px;
}

.modal *::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-800);
  border-radius: 20px;
  border-top: 16px solid var(--color-gray-100);
}

.modal {
  background-color: rgba(29, 30, 32, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: slide-in-bck-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.modal_wrapper {
  background-color: var(--color-gray-100);
  border: 2px solid var(--color-gray-800);
  border-radius: 9px;
  padding: 32px 24px 32px 32px;
  max-width: 676px;
  width: 100%;
  max-height: calc(100vh - 20%);
  position: relative;
  overflow: hidden;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.modal_content {
  overflow: scroll;
  overflow-x: hidden;
  height: 100%;
  padding-right: 16px;
}

.modal_exit_button {
  color: var(--color-blue-200);
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: 20px;
  outline: none;
  position: absolute;
  top: 20px;
  right: 20px;
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
    transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
