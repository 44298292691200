@import 'assets/css/index.css';

.alert {
  border-radius: 4px;
  display: flex;
  padding: 5px 20px;
  width: 100%;
  margin-bottom: 12px;
}

.alert.error {
  background-color: var(--color-gray-4);
}

.alert.warning {
  background-color: var(--color-gray-5);
}

.alert.info {
  background-color: var(--color-gray-6);
}

.alert.success {
  background-color: var(--color-gray-7);
}

.icon {
  width: 1.5em;
  fill: var(--color-white);
  margin-right: 20px;
}

.text {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2em;
}
