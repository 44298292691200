.progress {
  width: 100%;
  height: 6px;
  background: var(--color-gray-3);
}

.progress_bar {
  background-color: var(--color-blue-1);
  height: 100%;
  font-size: 0;
  line-height: 0;
  transition: width 0.3s linear;
}
