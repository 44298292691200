@import 'assets/css/index.css';

.main {
  /* height: calc(100vh - 80px); */
  margin-bottom: 100px;
}

.container {
  max-width: var(--container);
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

.my_project_content {
  padding-top: 40px;
}

.my_project_content .container {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  column-gap: 20px;
}
