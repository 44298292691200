@import 'assets/css/index.css';

.header {
  background-color: var(--color-gray-800);
  height: 80px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3;
}

.header_container {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1380px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

.header_logo {
  display: flex;
  align-items: center;
}

.header_title {
  color: var(--color-gray-100);
  font-size: 1.125em;
  font-weight: 500;
  margin: 0 0 0 16px;
}

.header_nav {
  color: var(--color-gray-100);
  display: flex;
}

@media (max-width: 940px) {
  .header_nav {
    display: none;
  }
}

.header_nav_item {
  color: var(--color-gray-100);
  text-decoration: none;
  font-size: 0.875em;
  line-height: 1em;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.header_nav_item:hover:not(.active) {
  color: var(--color-gray-300);
}

.header_nav_item svg {
  margin-right: 4px;
}

.header_nav_item + .header_nav_item {
  margin-left: 24px;
}

.active {
  position: relative;
}

.active:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(6px);
  height: 3px;
  width: 100%;
  border-radius: 10px;
  background-color: var(--color-blue-200);
}
