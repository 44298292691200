@import 'assets/css/index.css';

.background {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  min-height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 9999;
}

.background > div > svg {
  height: 100%;
}

.logo {
  margin-bottom: 30px;
  height: 45px;
}
