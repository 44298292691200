@import 'assets/css/index.css';

.background {
  align-items: center;
  background-color: var(--color-gray-2);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding-bottom: 180px;
  padding-top: 180px;
  width: 100%;
}

.logo {
  margin-bottom: 47px;
}

.box {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-3);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 564px;
  padding: 70px 60px;
  width: 100%;
}

.title {
  font-family: 'Roboto', sans-serif;
  color: var(--color-blue-2);
  font-size: 2em;
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0;
}

.text {
  font-family: 'Roboto', sans-serif;
  color: var(--color-blue-2);
  font-size: 1.5em;
  text-align: center;
  font-weight: normal;
  margin: 0;
}
