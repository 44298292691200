@import 'assets/css/index.css';

.select > div:first-child {
  border: 1px solid var(--color-gray-8);
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  width: 100%;
  min-height: 60px;
}

.select > [class*='control'] {
  min-height: 20px;
  padding: 10px 15px;
}

.select > div > div {
  padding: 0;
}

.select.error > div {
  border-color: var(--color-red-1);
}
