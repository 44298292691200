@import 'assets/css/index.css';

.message {
  border: 1px solid var(--color-gray-800);
  background-color: #eeee11;
  padding: 20px 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.message_text {
  font-size: 0.87em;
  line-height: 1em;
  font-weight: 400;
  color: var(--color-gray-800);
}

.message_icon {
  width: 16px;
  margin-right: 12px;
}
