:root {
  --maxWidth: 890px;
  --maxHeight: 525px;
}

.welcome {
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
}

.welcome_active {
  display: block;
}

.modal {
  position: fixed;
  width: 100%;
  max-width: var(--maxWidth);
  height: var(--maxHeight);
  top: calc(50% - (var(--maxHeight) / 2));
  left: calc(50% - (var(--maxWidth) / 2));
  z-index: 3;
  background-color: white;
  border-radius: 32px;
  overflow: hidden;
}

@media (max-width: 910px) {
  .modal {
    left: inherit;
  }
}
