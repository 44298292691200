.wrapper {
  margin-top: 46px;
  max-width: 825px;
  width: 100%;
}

.section + .section {
  margin-top: 74px;
}

.head {
  display: flex;
  justify-content: space-between;
}

.head + .boxes {
  margin-top: 32px;
}

.title {
  font-family: 'Roboto', sans-serif;
  color: var(--color-blue-2);
  line-height: 1.5em;
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 0;
  margin-top: 0;
}

.button {
  align-self: center;
}

.boxes {
  display: flex;
  column-gap: 30px;
  row-gap: 30px;
  flex-wrap: wrap;
}

.boxes > div {
  min-height: 280px;
  flex: 0 1 255px;
}
