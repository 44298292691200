@import 'assets/css/index.css';

.steps {
  height: 100%;
  padding: 72px 84px;
  border-radius: 32px;
}

.steps_flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.step {
  display: none;
  visibility: hidden;
  position: relative;
  z-index: 10;
}

.step.active {
  display: block;
  visibility: visible;
}

.step [class*='buttons'] {
  margin-top: 82px;
}

.step[data-step='0'] [class*='buttons'] {
  margin-top: 36px;
}

.hold {
  max-width: 320px;
  width: 100%;
}

.title {
  font-size: 32px;
  line-height: 38px;
  font-weight: 500;
  margin: 0 0 16px;
  color: var(--color-blue-2);

  animation: fade-in 0.9s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.desc {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0 0 16px;
  color: var(--color-blue-2);

  animation: fade-in 0.9s cubic-bezier(0.39, 0.575, 0.565, 1) 0.1s both;
}

.desc span {
  font-weight: 700;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
