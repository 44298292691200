@import 'assets/css/index.css';

.fieldset {
  border: none;
  padding: 0;
  margin-bottom: 32px;
  margin-left: 0;
  margin-right: 0;
}

.label {
  color: var(--color-gray-800);
  font-size: 0.875em;
  line-height: 1em;
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.label.error {
  color: var(--color-red-1);
}

.textarea {
  font-family: 'Roboto', sans-serif;
  border: 1px solid var(--color-gray-800);
  border-radius: 5px;
  font-size: 0.87em;
  line-height: 1.25em;
  padding: 18px 15px;
  width: 100%;
}

.textarea::placeholder {
  color: var(--color-gray-8);
}

.textarea.error {
  border-color: var(--color-red-1);
}

.error_message {
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8em;
  margin-top: 6px;
  color: var(--color-red-1);
}
