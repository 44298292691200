@import 'assets/css/index.css';

.main {
  padding: 80px 0;
}

.container {
  max-width: 676px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

.form {
  margin-bottom: 32px;
}

.form textarea {
  height: 212px;
}

.cta {
  text-align: right;
}

.btn {
  max-width: 240px;
  width: 100%;
}

/* Checkbox Group */
.fieldset {
  border: none;
  padding: 0;
  margin-bottom: 20px;
}

.checkbox {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}

.label_main {
  color: var(--color-gray-800);
  /* font-family: "Roboto", sans-serif; */
  font-size: 0.87em;
  line-height: 1em;
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.label_main.error {
  color: var(--color-error);
}

.description {
  font-size: 0.87em;
  line-height: 1.1em;
  color: var(--color-gray-500);
  display: inline-block;
  margin-bottom: 20px;
}

.label {
  font-size: 0.87em;
  line-height: 1em;
  color: var(--color-gray-500);
  height: 40px;
  border-radius: 30px;
  border: 1px solid var(--color-gray-500);
  padding: 12px 16px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 14px;
  margin-bottom: 16px;
  max-width: 428px;
  overflow: hidden;
}

.label.active {
  background-repeat: no-repeat;
  background-color: var(--color-blue-200);
  border: 1px solid var(--color-blue-200);
  background-size: 18px 12px;
  background-position: 10px center;
  color: var(--color-gray-100);
  font-weight: 500;
}

.error_message {
  display: block;
  color: var(--color-error);
  font-size: 0.8em;
  margin-top: 6px;
}
