@import 'assets/css/index.css';

.layout_loggedin {
  margin-top: 80px;
}

.footer {
  width: 100%;
  color: white;
  text-align: center;
  display: block;
  padding: 10px 0;
}

.footer span {
  color: var(--color-blue-200);
  font-size: 0.625em;
  line-height: 0.75em;
  font-weight: 400;
}
