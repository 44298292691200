@import 'assets/css/index.css';

.wrapper {
  margin-top: 65px;
  max-width: 445px;
  width: 100%;
}

.title,
.description {
  font-family: 'Roboto', sans-serif;
  color: var(--color-blue-2);
  line-height: 1.5em;
}

.title {
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 0;
  margin-top: 0;
}

.description {
  font-size: 16px;
}

.description a {
  color: var(--color-blue-1);
}

.line {
  margin-bottom: 45px;
  margin-top: 73px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hours_availability {
  font-family: 'Roboto', sans-serif;
  color: var(--color-blue-2);
  font-size: 1em;
}

.availability_day {
  border-bottom: 1px solid var(--color-gray-3);
  text-align: center;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: 'Roboto', sans-serif;
  color: var(--color-blue-2);
  font-size: 1em;
  margin-bottom: 22px;
  min-width: 95px;
}

.availability_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.availability_weekday {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add_availability {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.available_hour_period {
  font-family: 'Roboto', sans-serif;
  color: var(--color-blue-1);
  font-size: 0.8em;
  margin-bottom: 10px;
  margin-top: 0;
  cursor: pointer;
}
