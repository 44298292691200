@import 'assets/css/index.css';

.project_section {
  margin-bottom: 24px;
}

.project_section_wrap:first-of-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.project_section_title {
  font-size: 1.25em;
  line-height: 1.4375em;
  color: var(--color-gray-800);
  font-weight: 700;
  margin: 0 0 10px;
}

.project_section_desc {
  font-size: 0.875em;
  line-height: 1em;
  color: var(--color-gray-600);
  font-weight: 500;
  margin: 0;
}

.project_section_quantity {
  font-size: 0.875em;
  line-height: 1em;
  color: var(--color-gray-600);
  font-weight: 700;
  margin: 0;
}

.project_section_link {
  font-size: 0.875em;
  line-height: 0.875em;
  color: var(--color-blue-200);
  font-weight: 500;
  text-decoration: none;
}

.project_section_link:hover {
  text-decoration: underline;
}

.project_section_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px 20px;
  padding-bottom: 16px;
}

@media (max-width: 840px) {
  .project_section_list {
    grid-template-columns: 1fr 1fr;
  }
}

.fake_box {
  background-color: var(--color-gray-300);
  border-radius: 9px;
}

.project_section_pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 48px;
}

.project_paginate_per_page_text {
  color: var(--color-gray-800);
  font-size: 0.8em;
  font-weight: 400;
  margin-right: 8px;
}

.project_paginate_per_page_select {
  border: none;
  background: transparent;
  color: var(--color-blue-200);
  width: 50px;
  height: 24px;
  padding-right: 14px;
  font-size: 0.8em;
  font-weight: 700;
}

.project_paginate_per_page + .project_paginate_number {
  margin-left: 16px;
}
