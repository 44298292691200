.breadcrumbs {
  margin-bottom: 32px;
  font-size: 0.87em;
  font-weight: 500;
}

.breadcrumbs > * {
  margin-right: 4px;
}

.breadcrumbs_link {
  color: var(--color-blue-200);
}

.breadcrumbs_link + svg {
  color: var(--color-blue-200);
}

.breadcrumbs_link:hover {
  text-decoration: none;
}
