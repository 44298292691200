@import 'assets/css/index.css';

.section {
  margin-bottom: 64px;
  padding: 32px 0;
}

.container {
  max-width: var(--container);
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}
