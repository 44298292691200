@import 'assets/css/index.css';

.nav_user {
  position: relative;
  display: flex;
}

.nav_user_preview {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.nav_user_name {
  font-size: 0.875em;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--color-gray-100);
  margin-left: 4px;
}

.nav_user_image {
  width: 40px;
  height: 40px;
  background-color: var(--color-blue-200);
  border-radius: 100%;
  margin-left: 16px;
  font-size: 1em;
  font-weight: 500;
  color: var(--color-gray-100);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.nav_user_image img {
  width: 100%;
}

.nav_user_logout {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.nav_user_preview:hover > nav {
  transform: translateY(0);
  transition: transform 0.3s ease, opacity 0.4s ease, visibility 0.5s ease;
  opacity: 1;
  z-index: 2;
  visibility: visible;
}

.nav_user_preview > svg {
  transition: transform 0.3s ease;
}

.nav_user_preview:hover > svg {
  transform: rotate(180deg);
}
