@import 'assets/css/index.css';

.detail {
  border: 2px solid var(--color-gray-800);
  padding: 32px;
  border-radius: 9px;
  min-height: 471px;
}

.detail_title {
  margin-top: 16px;
  margin-bottom: 32px;
  font-size: 1.5em;
  line-height: 1.75em;
}

.detail_section {
  margin-bottom: 25px;
  display: flex;
  column-gap: 46px;
}

.detail_section + .detail_label {
  color: var(--color-blue-200);
}

.detail_item {
  flex: 1;
  max-width: 164px;
}

.detail_label {
  font-size: 0.87em;
  line-height: 1em;
  font-weight: 700;
  color: var(--color-gray-500);
  margin-bottom: 8px;
  display: block;
}

.detail_text {
  font-size: 0.87em;
  line-height: 1.3em;
  font-weight: 400;
  color: var(--color-gray-800);
  margin: 0 0 24px;
}

.detail_buttons button + button {
  margin-left: 16px;
}

.detail_link {
  font-size: 12px;
  line-height: 24px;
  color: var(--color-blue-1);
}

.detail_link:hover {
  text-decoration: none;
}
