@import 'assets/css/index.css';

.background {
  align-items: center;
  background-color: var(--color-gray-2);
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  padding-top: 100px;
  min-height: 100vh;
}

.logo {
  margin-bottom: 47px;
}

.box {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-3);
  border-radius: 3px;
  max-width: 564px;
  padding: 70px 60px;
  width: 100%;
}

.title {
  color: var(--color-blue-2);
  font-family: 'Roboto', sans-serif;
  font-size: 1.5em;
  font-weight: 500;
  margin: 0;
}

.description {
  color: var(--color-blue-2);
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
}
