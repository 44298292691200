@import 'assets/css/index.css';

.project_paginate_number a {
  width: 24px;
  height: 24px;
  display: inline-block;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-gray-800);
  font-weight: 400;
  font-size: 0.8em;
}

.project_paginate_number a:hover:not(.active) {
  color: var(--color-blue-200);
  font-weight: 700;
}

.project_paginate_number a.active {
  border-radius: 50%;
  background-color: var(--color-blue-200);
  color: var(--color-gray-100);
  font-weight: 500;
}

.project_paginate_number a.prev {
  margin-right: 6px;
  transform: rotate(180deg) scale(1.4);
}

.project_paginate_number a.next {
  margin-left: 6px;
  transform: scale(1.4);
}
