@import 'assets/css/index.css';

.main {
  padding-top: 48px;
  padding-bottom: 64px;
}

.section_profile {
  margin-bottom: 64px;
}

.section_links {
  margin-bottom: 64px;
}

.container {
  max-width: var(--container);
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

.welcome {
  /* margin-top: 48px; */
  margin-bottom: 24px;
}

.welcome_name {
  color: var(--color-blue-200);
  font-size: 2em;
  font-weight: 700;
  margin: 0 0 10px;
}

.welcome_doamin {
  display: flex;
}

.welcome_doamin_item {
  color: var(--color-gray-800);
  font-size: 0.875em;
  line-height: 1.25em;
  position: relative;
}

.welcome_doamin_item + .welcome_doamin_item {
  margin-left: 10px;
}

.welcome_doamin_item + .welcome_doamin_item:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: calc(50% - 5px);
  transform: translateX(-5px);
  width: 2px;
  height: 10px;
  background-color: var(--color-gray-800);
}

.welcome_doamin_label {
  font-weight: 700;
  padding-right: 2px;
}

.banner_refer {
  background-color: var(--color-blue-200);
  border-radius: 7px;
  padding: 24px 24px 24px 192px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  position: relative;
}

.banner_refer_title {
  color: var(--color-gray-100);
  font-size: 1.5em;
  line-height: 1.75em;
  font-weight: 900;
  margin: 0;
}

.banner_refer_domains {
  color: var(--color-gray-100);
  font-size: 1.125em;
  line-height: 1.3125em;
  font-weight: 500;
  margin: 0;
}

.banner_refer:before,
.banner_refer:after {
  content: '';
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  left: 0;
  transition: transform 0.3s ease;
}

.banner_refer:before {
  background-color: var(--color-gray-100);
  transform: translateX(40px);
}

.banner_refer:after {
  background-color: var(--color-blue-200);
  border: 5px solid #000;
  transform: translateX(88px);
}

.banner_refer:hover:before {
  transform: translateX(45px);
}
.banner_refer:hover:after {
  transform: translateX(83px);
}

.banner_photo {
  margin-bottom: 32px;
}

.banner_photo img {
  object-fit: contain;
  width: 100%;
  display: block;
}

.blank {
  width: 100%;
  background-color: gray;
  height: 4rem;
}

.update_profile_warning_box {
  width: 100%;
  background-color: #f1f3f9;
  padding: 25px 10px;
  margin-top: -50px;
  text-align: center;
}

.update_profile_warning_btn {
  position: absolute;
  bottom: 24px;
  left: 24px;
  right: 24px;
  width: calc(100% - 48px);
}

.project_box_btn {
  position: inherit;
  bottom: 24px;
  left: 24px;
  right: 24px;
  width: calc(50% - 48px);
  margin: auto;
}

.project_section_link {
  display: inline;
  font-size: 0.875em;
  line-height: 0.875em;
  color: var(--color-blue-200);
  font-weight: 500;
  text-decoration: underline;
  margin-left: 0;
}
